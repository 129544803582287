<template>
  <div>
    <!-- <StoryEditorScenes /> -->
    <span>{{c}}</span>
    <v-btn @click="run">
      RUN
    </v-btn>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { searchSortedList } from '../helpers/utils';
import StoryEditorScenes from '../components/StoryEditorScenes.vue';

export default {
  layout: 'editor',
  components: {
    StoryEditorScenes,
  },
  data() {
    return {
      storyId: 'auto-dr1UuQkfYjcpCaA9MTT3da',
      c: -1,
      i: 0,
    };
  },
  mounted() {
    // this.$store.dispatch('story/loadAllDatas', this.storyId)
    //   .then(() => {
    //     console.log('loaded');
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  },
  methods: {
    run() {
      const list = [6, 0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13];
      // eslint-disable-next-line no-plusplus
      const c = searchSortedList(list, this.i++, (a, b) => a - b);
      this.c = c;
    },
  },
};
</script>

<style>

</style>
